@use "../../../partials/colors" as*;

.broadcast-form {
    max-width: 80rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: $wine-red;
    //border: 1px solid $wine-red;
    margin-bottom: 2rem;
    padding: 1rem 2rem;
    background-color: $golden;

    &__top {
        display: flex;
        gap: 2rem;
        align-items: center;
        justify-content: center;
    }

    &__main {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
    }

    &__titles {
        flex-basis: 46%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

}