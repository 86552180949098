@use "../../partials/colors" as*;

.wysiwyg {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    text-align: left;

    &__label {
        margin: .5rem 0;
        font-size: 1.125rem;
        font-weight: 600;
    }

    &__editor-box {
        padding: 1rem;
        border: 1px solid $wine-red;
        background-color: white;
        border-radius: 10px;
        height: 550px;
        overflow: scroll;

        &::-webkit-scrollbar {
            width: 12px;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px $golden;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px $wine-red;
        }

        &:focus-within {
            outline: 2px solid $wine-red;
        }


    }

    &__editor {
        min-height: 200px;
    }
}

.rsw-editor {
    min-height: 300px;
    background-color: white;
}