@use "../../partials/colors" as*;

.announcement-single-draft {
    gap: 1rem;
    display: flex;
    border: 2px solid $wine-red;
    text-decoration: none;
    color: $wine-red;
    align-items: flex-start;
    position: relative;
    padding-right: 3rem;

    &__date {
        font-weight: 600;
        border-right: 4px solid $wine-red;
        padding: 1rem 1.5rem;
        font-size: 1.25rem;
        background-color: $golden;
        min-width: 8rem;
        text-decoration: none;
        color: $wine-red;

        &:hover {
            background-color: $wine-red;
            color: $beige;
        }
    }

    &__text {
        text-decoration: none;
        color: $wine-red;
        padding-right: .5rem;
    }

    &__title {
        padding: 0;
        margin-top: .5rem;
        text-decoration: none;
        font-size: 1rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;

        &:hover {
            text-decoration: underline;
        }
    }

    &__content {
        width: 100%;
        overflow: scroll;
        max-height: 12.5rem;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}