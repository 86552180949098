@use "../../../partials/colors" as*;

.saved-pdfs {
    padding: 0 2rem 4rem;
    width: 100%;
    max-width: 50rem;
    margin: 0 auto;

    &__single {
        margin: 0 auto .5rem auto;
        color: $wine-red;
        font-size: 1.25rem;
        font-weight: 400;
        display: flex;
        justify-content: space-between;
    }

    &__icon {
        width: 1rem;
        height: 1rem;
        fill: $wine-red;
    }

    &__icon-box {
        padding: 0 .5rem;
    }

    &__text {
        cursor: pointer;
        color: $wine-red;
        text-decoration: none;
        width: 100%;

        &:hover {
            text-decoration: underline;
        }
    }

    &__link {
        color: $wine-red;
        text-decoration: none;
        width: 100%;
    }

    &__buttons {
        display: flex;
        gap: 2rem;
    }

}