@use "../../../partials/colors" as*;
@use "../../../partials/button" as*;

.feast-form {
    max-width: 70rem;
    margin: 0 auto;
    padding: .5rem 2rem;
    color: $wine-red;

    &__heading {
        border-bottom: 2px solid $wine-red;
        width: 100%;
        text-align: center;
    }

    &__subheading {
        font-size: 1.25rem;
        width: 100%;
        font-weight: bold;
        padding: .5rem 0;
    }

    &__set-date {
        display: flex;
        flex-direction: column;
        padding: .5rem 0;
    }

    &__date-input {
        padding: .5rem;
    }

    &__calendar-entry {
        width: 100%;
    }

    &__single-calendar-entry {
        display: grid;
        grid-template-columns: 1fr 1fr;
        background-color: $beige;
        border: 2px solid $wine-red;
        padding: 1rem;
    }

    &__titles {
        padding: 1rem 0;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }


    &__content {
        display: flex;
        flex-direction: column;
    }

    &__content-radio {
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;
    }

    &__content-option {
        display: block;
        cursor: pointer;
        background-color: $beige;
        margin-bottom: 5px;
        padding: 10px;
        border-radius: 5px;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: white; // Lighten on hover for better user feedback
        }
    }

    &__content-option.selected {
        background-color: white;
    }

    &__submit {
        @extend .button
    }

}