@use "../../../partials/colors" as*;


.edit-pdf {
    &__form {
        width: 30rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2rem;
        gap: 1rem;
    }
}