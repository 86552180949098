@use "../../partials/colors"as*;

.bg-confirmation {
    padding: 1rem;
    display: flex;
    justify-content: space-around;
    width: 100%;
    border-bottom: 2px solid $wine-red;
    &__text{
        margin: 0 1rem;
        font-size: 1.125rem;
        font-weight: 600;
    }
}