@use "../../../partials/colors" as*;

.single-priest-resource {
    display: flex;
    gap: 1rem;

    &__container {
        display: flex;
        flex-direction: column;
        gap: .75rem;
    }

    &__change {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &__title {
        color: $wine-red;
        font-size: 1.25rem;
        line-height: 1.2;

        &:hover {
            text-decoration: underline;
            font-style: italic;
        }
    }

    &__title-link {
        text-decoration: none;
    }

    &__description {
        font-size: 1rem;
        color: $wine-red;
    }

    // &__link {
    //     text-decoration: none;
    //     color: $wine-red;
    //     align-self: flex-end;

    //     &::after {
    //         content: ">>";
    //     }

    //     &:hover {
    //         font-style: italic;
    //     }
    // }
}