@use "../../partials/colors"as*;

.delete-message {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 2;
    &__box {
        margin-left: 16rem;
        background-color: $beige;
        border: 4px solid $wine-red;
        border-radius: .75rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 2rem;
        text-align: center;
        gap: 2rem;
    }
    &__icon {
        height: 3rem;
        width: 3rem;
    }
    &__message {
        font-size: 1.1rem;
        font-style: italic;
        max-width: 55ch;
    }
    &__title {
        font-size: 1.25rem;
        width: 100%;
    }
    &__list {
        display: flex;
        padding: 0;
        list-style: none;
        justify-content: space-evenly;
        flex-wrap: wrap;
        margin-top: 2rem;
    }
    &__link {
        text-decoration: none;
        font-size: 1.1rem;
        color: $wine-red;
        border: 2px solid $wine-red;
        padding: .25rem 1rem;
        border-radius: 10%;
        &:hover {
            background-color: $golden;
        }
    }
    &__confirmation {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 2rem;
    }
    &__button {
        width: 10rem;
        padding: .5rem .25rem;
        background-color: $wine-red;
        color: $beige;
        border-radius: 4px;
        border: 2px solid $wine-red;
        font-size: 1rem;
        font-weight: bold;
        cursor: pointer;
        &:hover {
            color: $wine-red;
            background-color: $golden;
        }
        &:active {
            transition: .2s;
            transform: scale(.90);
        }
    }
}