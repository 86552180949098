@use "../../partials/colors"as*;
.image-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 2;
    overflow: hidden;
    &__modal {
        min-height: 14rem;
        min-width: 25rem;
        position: fixed;
        padding: 2rem;
        background-color: $beige;
        border: 4px solid $wine-red;
        max-height: 70vh;
        overflow: scroll;
        &::-webkit-scrollbar {
            width: 12px;
        }
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px $golden; 
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px $wine-red; 
        }
    }
    &__close {
        height: 1rem;
        width: 1rem;
        position: absolute;
        top: 2rem;
        right: 2rem;
        cursor: pointer;
    }
    &__form {
        display: flex;
        flex-direction: column;
    }
    &__main {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 1rem;
        gap: 4rem;
    }
    &__image {
        cursor: pointer;
        align-self: flex-start;
        display: flex;
        flex-direction: column;
    }
    &__preview {
        width: 15rem;
        background-color: white;
        cursor: pointer;
    }
    &__input {
        width: 0;
        height: 0;
        &::-webkit-file-upload-button {
            visibility: hidden;
          }
    }
    &__descriptions {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    &__description {
        display: flex;
        flex-direction: column;
        gap: .25rem;
    }
    &__text {
        padding: .5rem;
        border: 1px solid $wine-red;
        border-radius: 10px;
        &:focus {
            outline: 2px solid $wine-red;        
        }
    }
    &__bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__saved {
        height: 14rem;
        width: 100%;
        overflow: scroll;
        &::-webkit-scrollbar {
            width: 12px;
        }
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px $golden; 
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px $wine-red; 
        }
    }
    &__single {
        width: 100%;
    }
    &__submit {
    margin: 2rem auto;
    width: 10rem;
    padding: .5rem .25rem;
    background-color: $wine-red;
    color: $beige;
    border-radius: 4px;
    border: 2px solid $wine-red;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    &:hover {
        color: $wine-red;
        background-color: $golden;
    }
    &:active {
        transition: .2s;
        transform: scale(.90);
    }
    
}
}

.chosen {
    border: 4px solid $wine-red;
}