@use "../../partials/colors" as*;

.create-gallery {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 2;

    &__box {
        margin-left: 16rem;
        background-color: $beige;
        border: 4px solid $wine-red;
        border-radius: .75rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 2rem;
        text-align: center;
        gap: 2rem;
        max-height: 70vh;
        overflow: scroll;
        &::-webkit-scrollbar {
            width: 12px;
        }
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px $golden; 
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px $wine-red; 
        }
    }

    &__close {
        height: 1.5rem;
        width: 1.5rem;
        cursor: pointer;
    }

}