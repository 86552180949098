@use "./partials/colors" as*;

.app {
  display: flex;
  position: relative;

  &__main {
    flex-grow: 1;
    background-color: $beige;
    min-height: 100vh;
  }
}