@use "../../partials/colors" as*;

.modal {
    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;

    }

    &__container {
        background-color: $beige;
        padding: 3rem 20px 20px;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        max-width: 50rem;
        width: 100%;
        position: relative;
    }

    &__close {
        position: absolute;
        top: 2rem;
        right: 2rem;
        cursor: pointer;
        //background-color: $golden;
        border: none;
        padding: .25rem;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            scale: .9;
        }
    }

    &__x {
        height: 1rem;
        width: 1rem;
    }
}