@use "../../partials/colors"as*;
.single-article {
    position: relative;
    border: 2px solid $wine-red;
    width: 100%;
&__link {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: flex-start;
    cursor: pointer; 
    text-decoration: none;
    color: $wine-red;
    &:hover {
        background-color: $golden;
    }
}
&__left {
    margin: 1rem;
    min-width: 16rem;
    max-width: 16rem;
    height: 9rem;
    overflow: hidden;
}
&__text {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
&__title {
    flex-grow: 1;
    border-bottom: 2px solid $wine-red;
    margin-bottom: .5rem;
    margin-right: 1rem;
    width: 100%;
    text-decoration: none;
    padding: 1rem;
    font-size: 1.25rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
&__content {
    margin: 0 3rem 0 2rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
}