@use "../../../partials/colors" as*;

.dnd-res {
    height: 25rem;
    overflow-y: scroll;

    &__resource {
        background-color: $golden;
        padding: .5rem 1rem;
        border-radius: 35px;
        margin: .5rem 0;
        font-size: 1.1rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
    }
}