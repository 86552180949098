@use "../../../partials/colors" as*;

.calendar-single {
    max-width: 100%;
    margin: 0 auto .5rem auto;
    display: grid;
    grid-template-columns: 2.5% 2.5% 7.5% 40% 7.5% 40%;
    gap: .5rem;
    color: $wine-red;
    font-size: 1rem;
    color: black;
    font-weight: 400;


    &__date {
        font-weight: inherit;
        min-width: 2.5rem;
    }

    &__entry {
        display: flex;
        font-weight: inherit;
        width: 40ch;
    }

    &__title {
        font-size: 1rem;
        margin: 0;
        padding: 0;
    }

    &__bold {
        font-weight: bold;
    }

    &__red {
        color: red;
    }
}