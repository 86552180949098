@use "../../../partials/colors" as*;

.saved-resources {
    padding: 0 2rem 4rem;
    width: 100%;

    &__single {
        width: 80%;
        margin: 0 auto .5rem auto;
        color: $wine-red;
        font-size: 1.25rem;
        font-weight: 400;
        display: grid;
        grid-template-columns: 80% 10% 10%;
        align-items: center;
        justify-items: center;
    }

    &__icon {
        width: 1rem;
        height: 1rem;
        fill: $wine-red;
    }

    &__icon-box {
        padding: 0 .5rem;
    }

    &__text {
        cursor: pointer;
        color: $wine-red;
        text-decoration: none;
        width: 100%;

        &:hover {
            text-decoration: underline;
        }
    }

    &__link {
        color: $wine-red;
        text-decoration: none;
        width: 100%;
    }

}