@use "../../partials/colors" as*;


.toggle {
  background: $golden;
  margin: 1rem auto;
  border-radius: 10px;
  border: 1px solid $wine-red;
  overflow: hidden;
  width: 240px;
  text-align: center;
  font-size: 1rem;
  letter-spacing: 1px;
  color: $wine-red;
  position: relative;
  padding-right: 120px;

  &:before {
    content: "Draft";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    pointer-events: none;
  }

  &__checkbox {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;

    &:checked+.toggle__label:before {
      transform: translateX(120px);
      transition: transform 300ms ease-in-out;
    }

    &+.toggle__label {
      position: relative;
      padding: .5rem 0;
      display: block;
      user-select: none;
      pointer-events: none;

      &:before {
        content: "";
        background: $beige;
        border: 2px solid $wine-red;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 10px;
        transform: translateX(0);
        transition: transform 300ms;
      }
    }
  }

  &__span {
    position: relative;
  }
}