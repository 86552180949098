@use "../../../partials/colors" as*;

.form-box {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: $wine-red;
    margin: 0 auto auto;
    padding: 1rem 2rem;
    background-color: $golden;
}