@use "../../partials/colors" as*;
@use "../../partials/breakpoints" as*;

.dashboard {
    background-color: $golden;
    min-height: 100vh;
    max-width: 17rem;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-shadow: rgba(111, 11, 32, 0.3) 0px 10px 20px -1px, rgba(111, 11, 32, 0.1) 0px 0px 0px 2px;

    &__list {
        list-style: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        margin: 0;
        border: 4px solid $wine-red;
    }

    &__item {
        padding: 0;
        margin: 0;
        background-color: $wine-red;
    }

    &__link {
        text-decoration: none;
        cursor: pointer;
        padding-left: 1rem;
        display: flex;
        align-items: center;
        background-color: $wine-red;
        border: 1px solid $wine-red;

        &:hover {
            color: $wine-red;
            transform: translateX(1rem);
        }
    }

    &__button {
        background-color: transparent;
        border: none;
        width: 100%;
        padding: 1.5rem 0;
        cursor: pointer;
    }

    &__icon {
        width: 1.5rem;
        height: 1.5rem;
        object-fit: contain;
        margin-right: 1rem;

        &>svg {
            width: 1.5rem;
            height: 1.5rem;
            fill: $beige;
            fill-rule: evenodd;
        }
    }

    &__text {
        background-color: $golden;
        padding: .8rem 1rem;
        flex-grow: 1;
        color: $wine-red;
        font-size: 1rem;
        font-weight: bold;
    }

    &__home {
        display: flex;
        align-items: center;
        padding: .5rem 0;
    }

    &__logo {
        background-color: $golden;
        width: 50%;
    }
}

// .active>span {
//     color: $beige;
//     background-color: $wine-red;

//     &:hover {
//         color: $beige;
//         background-color: $wine-red;
//     }
// }