@use "../../../partials/colors" as*;

.add-category {
    width: 100%;
    color: $wine-red;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    gap: .5rem;
    text-align: center;

    &__title {
        border-bottom: 2px solid $wine-red;
        width: 100%;
    }
}