@use "../../../partials/colors" as*;

.pdf-upload {
    max-width: 31.25rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__label {
        width: 100%;
        padding: 10px 20px;
        color: $wine-red;
        border: 1px solid $wine-red;
        background-color: white;
        border-radius: 5px;
        cursor: pointer;
        font-weight: bold;
        text-align: center;
    }

    &__input {
        display: none;
    }
}