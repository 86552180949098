@use "../../../partials/colors" as*;

.single-event {
    width: 100%;
    display: flex;
    border: 1px solid $wine-red;
    padding: .5rem;
    align-items: center;
    gap: 1rem;

    &__date {
        font-weight: 600;
        font-size: 1rem;
        background-color: $wine-red;
        color: $beige;
        max-height: 3rem;
        min-width: 8rem;
        padding: .5rem 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__title {
        color: $wine-red;
        margin: 0 1rem;
        font-size: 1rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}