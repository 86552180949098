@use "../../partials/colors"as*;
.worship-office {
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: $wine-red;
    background-color: $beige;
    margin: 0 3rem;
    &__top {
        width: 100%;
    }
    &__middle {
        display: flex;
        gap: 3rem;
    }
    &__youtube {
        margin: 2rem 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3rem;
        &> :first-child {
            flex-basis: 50%;
        }
    }
    &__image-preview {
        width: 20rem;
    }
    &__title {
        border-bottom: 2px solid $wine-red;
        width: 100%;
    }
    &__subtitle {
        border-bottom: 2px solid $wine-red;
    }
    &__language {
        flex-basis: 50%;
        display: flex;
        flex-direction: column;
    }
    &__bottom {
        display: flex;
        gap: 2rem;
        padding: 1rem 0;
    }
}