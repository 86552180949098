@use "../../../partials/colors" as*;

.new-feast {
    max-width: 50rem;
    margin: 0 auto;
}

// .event {
//     padding: 0 2rem;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     text-align: center;
//     color: $wine-red;
//     background-color: $beige;
//     margin: 0 3rem;

//     &__title {
//         border-bottom: 2px solid $wine-red;
//         width: 100%;
//     }

//     &__multilingual {
//         display: flex;
//         gap: 3rem;
//     }

//     &__language-specific {
//         flex-basis: 50%;
//         display: flex;
//         flex-direction: column;
//     }

//     &__subtitle {
//         margin: 1rem 0;
//         border-bottom: 2px solid $wine-red;
//     }

//     &__submit {
//         margin: 2rem auto;
//         width: 10rem;
//         padding: .5rem .25rem;
//         background-color: $wine-red;
//         color: $beige;
//         border-radius: 4px;
//         border: none;
//         font-size: 1rem;
//         font-weight: bold;
//         cursor: pointer;

//         &:hover {
//             color: $wine-red;
//             background-color: $golden;
//             border: 2px solid $wine-red;
//         }

//         &:active {
//             transition: .2s;
//             transform: scale(.90);
//         }
//     }
// }