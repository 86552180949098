@use "../../partials/colors" as*;
.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    color: $wine-red;
    gap: 1rem;
    &__fields {
        max-width: 20rem;
    }
    &__label {
        font-size: 1.1rem;
        margin-bottom: .5rem;
    }
    &__input {
        width: 18rem;
        margin-bottom: .5rem;
        padding: .5rem;
        border: 1px solid $wine-red;
        &:focus {
            outline: 2px solid $wine-red;
        }
    }
    &__error {
        font-size: 1.1rem;
    }
}