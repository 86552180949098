@use "../../../partials/colors" as*;

.modify-button {
    background-color: $golden;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    color: $wine-red;
    padding: .5rem;
    transition: background-color 0.3s ease;

    &>span>svg {
        fill: $wine-red;
    }

    &:hover {
        background-color: $wine-red;
        color: $beige;

        &>span>svg {
            fill: $beige;
        }
    }

    &:active {
        scale: .95;
    }

    &__icon-box {
        width: 1rem;
        height: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__icon {
        width: 100%;
        height: 100%;
    }

    &__text {
        font-size: 1rem;
        padding: 0;
        padding-top: 2px;
        margin: 0;
        // transform: translateY(2px);
    }

    &--dark {
        background-color: $wine-red;
        color: $beige;
    }
}