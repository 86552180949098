@use "../../partials/colors" as*;
.quick-nav {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
    &__title {
        color: $wine-red;
        margin: 5rem;
    }
    &__list {
        list-style: none;
        width: 50rem;
        padding: 0;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 2rem;
    }
    &__item {
        flex-basis: 40%;
        margin: 0;
        background-color: $wine-red;
    }
    &__link {
        text-decoration: none;
        cursor: pointer;
        padding-left: 1rem;
        display: flex;
        align-items: center;
        color: $wine-red;
        font-size: 1rem;
        font-weight: bold;
        background-color: $wine-red;
        border: 1px solid $wine-red;
    }
    &__icon {
        width: 1.5rem;
        height: 1.5rem;
        object-fit: contain;
        margin-right: 1rem;
    }
    &__text {
        color: beige;
        padding: 1rem;
        padding-left: 0;
        flex-grow: 1;
    }
}