@use "./colors" as*;

.button {
    text-decoration: none;
    text-align: center;
    margin: 1rem auto;
    padding: .25rem 2rem;
    background-color: $wine-red;
    color: $beige;
    border-radius: 4px;
    outline: 2px solid $wine-red;
    border: none;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        color: $wine-red;
        background-color: $golden;
    }
}