@use "../../partials/colors"as*;

.error-message {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 2;
    &__box {
        margin-left: 16rem;
        background-color: $beige;
        border: 4px solid $wine-red;
        border-radius: .75rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 2rem;
        text-align: center;
    }
    &__icon {
        height: 3rem;
        width: 3rem;
    }
    &__message {
        max-width: 55ch;
        line-height: 1.5rem;
    }
    &__button {
        margin: 1rem auto;
        width: 10rem;
        padding: .5rem .25rem;
        background-color: $wine-red;
        color: $beige;
        border-radius: 4px;
        border: none;
        font-size: 1rem;
        font-weight: bold;
        cursor: pointer;
        &:hover {
            color: $wine-red;
            background-color: $golden;
            border: 2px solid $wine-red;
        }
        &:active {
            transition: .2s;
            transform: scale(.90);
        }
    }
}