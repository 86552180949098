@use "../../partials/colors" as*;

.image-gallery-page {
    margin-top: 2rem;
    padding: 0 3rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    &__top {
        display: flex;
        align-items: flex-end;
        justify-content: space-around;
        margin-bottom: 2rem;
        border-bottom: 4px solid $wine-red;
    }
    &__caution {
        background-color: $golden;
        color: $wine-red;
        width: 30%;
        margin: 1rem 0;
        padding: 1rem;
    }
    &__heading {
        font-size: 1.25rem;
    }
    &__button {
        transform: translateX(-2rem);
        text-decoration: none;
        text-align: center;
        margin: 0 10rem 0 0;
        padding: .5rem 2rem;
        background-color: $wine-red;
        color: $beige;
        border-radius: 4px;
        border: 4px solid $wine-red;
        font-size: 1.25rem;
        font-weight: bold;
        cursor: pointer;
        &:hover {
            color: $wine-red;
            background-color: $golden;
        }
    }
}