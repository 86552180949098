@use "../../../partials/colors" as*;

.input {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;

    &__label {
        margin: 0 0 .5rem 0;
        line-height: 1;
        font-size: 1rem;
        width: 100%;
        font-weight: 600;
    }

    &__field {
        padding: .5rem;
        border: 1px solid $wine-red;
        border-radius: 10px;
        resize: none;

        &:focus {
            outline: 2px solid $wine-red;
        }
    }
}