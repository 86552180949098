@use "../../../partials/colors" as*;

.modal {
    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__container {
        background-color: $beige;
        padding: 2rem 1.25rem 1.25rem;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        max-width: 50rem;
        width: fit-content;
        position: relative;
        max-height: 80vh;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 0.5rem;
        }

        &::-webkit-scrollbar-track {
            background: $beige;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $golden;
            border-radius: .25rem;
        }
    }

    &__close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        cursor: pointer;
        border: none;
        padding: .25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;

        &:hover {
            scale: 1.1;
        }
    }

    &__x {
        fill: $wine-red;
        height: 1.5rem;
        width: 1.5rem;
    }
}