@use "../../../partials/colors" as*;

.priest-resources-form {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    color: $wine-red;

    &__heading {
        border-bottom: 2px solid $wine-red;
        width: 100%;
    }

    &__main {
        display: flex;
        gap: 1rem;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        margin: .5rem auto;
    }

    &__column {
        flex-basis: 45%;
    }

    &__submit {
        margin: 2rem auto;
        width: 10rem;
        padding: .5rem .25rem;
        background-color: $wine-red;
        color: $beige;
        border-radius: 4px;
        border: none;
        font-size: 1rem;
        font-weight: bold;
        cursor: pointer;

        &:hover {
            color: $wine-red;
            background-color: $golden;
            border: 2px solid $wine-red;
        }

        &:active {
            transition: .2s;
            transform: scale(.90);
        }
    }
}