@use "../../../partials/colors" as*;

.saved-feasts {
    max-width: 65rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid $wine-red;
}